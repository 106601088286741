import { Container, Row, Col, Button, Card} from "react-bootstrap";
import { Link } from 'react-router-dom';
import '../App.css';
import Amarracao from "../assets/img/trabalhos/amarracao.jpeg";
import Anticosmico from "../assets/img/trabalhos/anticosmico.jpeg";
import Lilith from "../assets/img/trabalhos/lilith.jpeg";
import Lucifer from "../assets/img/trabalhos/lucifer.jpeg";
import Prosperidade from "../assets/img/trabalhos/prosperidade.jpeg";
import Separacao from "../assets/img/trabalhos/separacao.jpeg";

export const Trabalhos = () => {
  return (

    <section className="trabalhos" id="trabalhos">
    <Container>
    <h4 className="h4Title">Trabalhos Espirituais</h4>
    <h3 className="h3Subtitle">Tenha tudo o que você deseja</h3>
    <p className="pDescription">Realize trabalhos espirituais com os Mestres do Templo com total confiança e sigilo. <br/> 
        Receba auxílio e orientação da espiritualidade através de quem tem experiência e conhecimento.</p>
      <Row>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Amarracao} />
                <Card.Body>
                    <Card.Title className="cardTitle">Amarração Amorosa</Card.Title>
                    <Card.Text>
                    A Amarração Amorosa permite que um casal seja unido de forma intensa. Esse trabalho pode ser feito em várias linhas, como:
                    Pombagiras, Lilith, Daemons, Voodoo e Astarte.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Prosperidade} />
                <Card.Body>
                    <Card.Title className="cardTitle">Prosperidade</Card.Title>
                    <Card.Text>
                    O trabalho de Prosperidade é recomendado para pessoas que estão em busca de novas oportunidades financeiras,
                    e também auxilia a subir de cargo e ganhar promoções.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Separacao} />
                <Card.Body>
                    <Card.Title className="cardTitle">Separação de Casal</Card.Title>
                    <Card.Text>
                    Com o trabalho de Separação de Casal, o casal magiado vai se separar de forma intensa e definitiva, muitas das vezes
                    resultando até em ódio entre os envolvidos.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        </Row>
        <Row>
        <Col>
            <Card className="cardTrabalhos"> 
                <Card.Img variant="top" src={Lucifer} />
                <Card.Body>
                    <Card.Title className="cardTitle">Pacto com Lúcifer</Card.Title>
                    <Card.Text>
                        Lúcifer é o Deus do autoconhecimento, sabedoria e prosperidade. Com o Pacto Luciferiano, você pode conquistar tudo aquilo que você
                        sempre desejou.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Lilith} />
                <Card.Body>
                    <Card.Title className="cardTitle">Lilith</Card.Title>
                    <Card.Text>
                    Lilith é essencial para trabalhos amorosos, como em situações onde o homem não a
                    vê mais como mulher ou onde não há mais interesse por qualquer motivo, 
                    tornando você a prioridade dele.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Anticosmico} />
                <Card.Body>
                    <Card.Title className="cardTitle">Satanismo Anticósmico</Card.Title>
                    <Card.Text>
                    Para o Satanismo Anticósmico, nada é impossível. Conquiste tudo aquilo que você sempre desejou.
                    Os trabalhos com energia Anticósmica podem ser realizados para qualquer fim.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
      </Row>
      <Link to="/allJobs">
          <Button className="buttonTrabalhos">Todos os Trabalhos</Button>
        </Link>
    </Container>
    </section>
  );
}

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Sobre } from "./components/Sobre";
import { Trabalhos } from "./components/Trabalhos";
import { Footer } from "./components/Footer";
import { Consultas } from './components/Consultas';
import { Quimbanda } from './components/Quimbanda';
import { Presenciais } from './components/Presenciais';
import { Consagrados } from './components/Consagrados';
import { AllJobs } from './components/AllJobs';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path="/" element={
            <>
              <Banner />
              <Sobre />
              <Trabalhos />
              <Consultas />
              <Presenciais />
              <Quimbanda />
              <Consagrados />
            </>
          } />
          <Route path="/allJobs" element={<AllJobs />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

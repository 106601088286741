import { Container, Row, Col, Card } from "react-bootstrap";
import ImageBruno from "../assets/img/bruno.png";
import ImageCaio from "../assets/img/caio.png";
import navIcon1 from "../assets/img/nav-icon1.ico";
import navIcon2 from "../assets/img/nav-icon2.png";
import navIcon3 from "../assets/img/nav-icon3.png";
import LinkTree from "../assets/img/linktree-white-icon.webp";
import '../App.css';

export const Sobre = () => {
  return (
    <section className="about" id="about">
      <Container>
        <Row>
          <Col>
            <div>
              <h4 className="h4Title">Quem somos?</h4>
              <h3 className="h3Subtitle">Tatas de Quimbanda; Mestres Luciferianos e Satanistas</h3>
              <Row>
                <Col>
                  <Card className="aboutCard">
                    <Row noGutters>
                      <Col md={4}>
                        <Card.Img src={ImageBruno} className="imgCard"/>
                      </Col>
                      <Col md={8}>
                        <Card.Body>
                          <Card.Title className="cardTitle">Tata Bruno de Mulambo</Card.Title>
                          <Card.Text>
                            Há 15 anos, vivencio a espiritualidade na prática. <br/>
                            Ancestralidade como herança familiar, com aprofundamento por meio de diversos estudos nos diferentes territórios brasileiros.
                          </Card.Text>
                        </Card.Body>
                        
                        <div className="social-icon">
                        <h5 className="cardTitle">Redes Sociais</h5>
                            <a href="https://linktr.ee/tatabrunodemulambo" target="_blank" rel="noopener noreferrer">
                              <img src={LinkTree} alt="LinkTree" />
                            </a>
                          </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col>
                  <Card className="aboutCard">
                    <Row noGutters>
                      <Col md={4}>
                        <Card.Img src={ImageCaio} className="imgCard"/>
                      </Col>
                      <Col md={8}>
                        <Card.Body>
                          <Card.Title className="cardTitle">Tata Caio de Pinga Fogo</Card.Title>
                          <Card.Text>
                            Há 9 anos, vivencio a espiritualidade na prática. <br/>
                            Ancestralidade como herança familiar, com aprofundamento por meio de diversos estudos nos diferentes territórios brasileiros.
                          </Card.Text>
                        </Card.Body>
                        <div className="social-icon">
                            <h5 className="cardTitle">Redes Sociais</h5>
                            <a href="https://linktr.ee/ccanassa" target="_blank" rel="noopener noreferrer">
                              <img src={LinkTree} alt="LinkTree" />
                            </a>
                          </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

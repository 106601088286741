import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa o hook useHistory
import { NavBar } from "./NavBar";
import { Banner } from "./Banner";
import { Container, Row, Col, Card } from "react-bootstrap";
import '../App.css';
import Adocamento from "../assets/img/trabalhos/adocamento.jpeg";
import Azar from "../assets/img/trabalhos/azar.jpeg";
import Afastamento from "../assets/img/trabalhos/afastamento.jpeg";
import Amarracao from "../assets/img/trabalhos/amarracao.jpeg";
import Anticosmico from "../assets/img/trabalhos/anticosmico.jpeg";
import Atracao from "../assets/img/trabalhos/atracao.jpeg";
import Autoestima from "../assets/img/trabalhos/autoestima.jpeg";
import Blindagem from "../assets/img/trabalhos/blindagem.jpeg";
import Brochamento from "../assets/img/trabalhos/brochamento.jpeg";
import BrochamentoFem from "../assets/img/trabalhos/brochamentoFem.jpeg";
import Chora from "../assets/img/trabalhos/chora.jpeg";
import Cobranca from "../assets/img/trabalhos/cobranca.jpeg";
import Daemons from "../assets/img/trabalhos/daemons.jpeg";
import Dominio from "../assets/img/trabalhos/dominio.jpeg";
import Equilibrio from "../assets/img/trabalhos/equilibrio.jpeg";
import Exupombagira from "../assets/img/trabalhos/exupombagira.png";
import Fofoqueiro from "../assets/img/trabalhos/fofoqueiro.jpeg";
import Lilith from "../assets/img/trabalhos/lilith.jpeg";
import Limpeza from "../assets/img/trabalhos/limpeza.jpeg";
import Lucifer from "../assets/img/trabalhos/lucifer.jpeg";
import Maldicao from "../assets/img/trabalhos/maldicao.jpeg";
import Maoaberta from "../assets/img/trabalhos/maoaberta.jpeg";
import Morte from "../assets/img/trabalhos/morte.jpeg";
import Orgulho from "../assets/img/trabalhos/orgulho.jpeg";
import PactoPrincipes from "../assets/img/trabalhos/pactoprincipes.jpeg";
import Prosperidade from "../assets/img/trabalhos/prosperidade.jpeg";
import Separacao from "../assets/img/trabalhos/separacao.jpeg";
import TrabalhoPrincipes from "../assets/img/trabalhos/trabalhoprincipes.jpeg";
import Vendas from "../assets/img/trabalhos/vendas.jpeg";
import Virapensamento from "../assets/img/trabalhos/virapensamento.jpeg";

export const AllJobs = () => {
    useEffect(() => {
      // Remover tudo que vier após a barra "/" e manter apenas o domínio
      window.history.replaceState(null, null, window.location.origin);
    }, []);

  return (
    <section className="allJobs" id="allJobs">
      <NavBar />
      <Banner />
      <Container>
      <h4 className="h4TitleJobs">Trabalhos Espirituais</h4>
      <h3 className="h3Subtitle">Tenha tudo o que você deseja</h3>
      <p className="pDescription">Realize trabalhos espirituais com os Mestres e sacerdotes do Templo com total confiança e sigilo. <br/> 
        Receba auxílio e orientação da espiritualidade através de quem tem experiência e conhecimento.</p>
      <Row>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Amarracao} />
                <Card.Body>
                    <Card.Title className="cardTitle">Amarração Amorosa</Card.Title>
                    <Card.Text>
                    A Amarração Amorosa permite que um casal seja unido de forma intensa. Esse trabalho pode ser feito em várias linhas, como:
                    Pombagiras, Lilith, Daemons, Voodoo e Astarte.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Prosperidade} />
                <Card.Body>
                    <Card.Title className="cardTitle">Prosperidade</Card.Title>
                    <Card.Text>
                    O trabalho de Prosperidade é recomendado para pessoas que estão em busca de novas oportunidades financeiras,
                    e também auxilia a subir de cargo e ganhar promoções.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Separacao} />
                <Card.Body>
                    <Card.Title className="cardTitle">Separação de Casal</Card.Title>
                    <Card.Text>
                    Com o trabalho de Separação de Casal, o casal magiado vai se separar de forma intensa e definitiva, muitas das vezes
                    resultando até em ódio entre os envolvidos.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        </Row>
        <Row>
        <Col>
            <Card className="cardTrabalhos"> 
                <Card.Img variant="top" src={Lucifer} />
                <Card.Body>
                    <Card.Title className="cardTitle">Pacto com Lúcifer</Card.Title>
                    <Card.Text>
                        Lúcifer é o Deus do autoconhecimento, sabedoria e prosperidade. Com o Pacto Luciferiano, você pode conquistar tudo aquilo que você
                        sempre desejou.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Lilith} />
                <Card.Body>
                    <Card.Title className="cardTitle">Lilith</Card.Title>
                    <Card.Text>
                    Lilith é essencial para trabalhos amorosos, como em situações onde o homem não a
                    vê mais como mulher ou onde não há mais interesse por qualquer motivo, 
                    tornando você a prioridade dele.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Anticosmico} />
                <Card.Body>
                    <Card.Title className="cardTitle">Satanismo Anticósmico</Card.Title>
                    <Card.Text>
                    Para o Satanismo Anticósmico, nada é impossível. Conquiste tudo aquilo que você sempre desejou.
                    Os trabalhos com energia Anticósmica podem ser realizados para qualquer fim.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
      </Row>
      <Row>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Adocamento} />
                <Card.Body>
                    <Card.Title className="cardTitle">Adoçamento</Card.Title>
                    <Card.Text>
                    O Adoçamento é um trabalho recomendado para adoçar, amansar, melhorar uma situação ruim, apaziguar uma relação.
                    Também pode ser utilizado para deixar uma pessoa mais carinhosa e grudenta.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Afastamento} />
                <Card.Body>
                    <Card.Title className="cardTitle">Afastamento de Rival</Card.Title>
                    <Card.Text>
                    Com o afastamento de rival, você garante que aquela pessoa que te incomoda saia para sempre de sua vida.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Atracao} />
                <Card.Body>
                    <Card.Title className="cardTitle">Atração Sexual</Card.Title>
                    <Card.Text>
                    Esse trabalho pode ser feito para que uma pessoa específica ou pessoas em geral tenham um forte desejo sexual em você.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        </Row>
        <Row>
        <Col>
            <Card className="cardTrabalhos"> 
                <Card.Img variant="top" src={Autoestima} />
                <Card.Body>
                    <Card.Title className="cardTitle">Autoestima</Card.Title>
                    <Card.Text>
                        O trabalho de Autoestima te deixa mais confiante, se sentindo mais bonita e aumenta a sua autoestima.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Azar} />
                <Card.Body>
                    <Card.Title className="cardTitle">Jogos de Azar</Card.Title>
                    <Card.Text>
                    O trabalho de Jogos de Azar com Zé Pretinho permite que a sorte esteja ao seu lado em jogos de baixo valor.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Blindagem} />
                <Card.Body>
                    <Card.Title className="cardTitle">Blindagem Espiritual</Card.Title>
                    <Card.Text>
                    Com o trabalho de Blindagem Espiritual com Exu, você ficará protegido qualquer demanda que tentem fazer contra você.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
      </Row>
      <Row>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Brochamento} />
                <Card.Body>
                    <Card.Title className="cardTitle">Brochamento Masculino</Card.Title>
                    <Card.Text>
                    O nome é autoexplicativo. Faça esse trabalho para garantir que o órgão sexual do homem não irá funcionar com outras pessoas,
                    somente com você.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={BrochamentoFem} />
                <Card.Body>
                    <Card.Title className="cardTitle">Brochamento Feminino</Card.Title>
                    <Card.Text>
                    O trabalho de Brochamento Feminino acaba com a libido da mulher, tirando toda e qualquer vontade de manter relações sexuais
                    com qualquer pessoa menos você.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Cobranca} />
                <Card.Body>
                    <Card.Title className="cardTitle">Cobrança</Card.Title>
                    <Card.Text>
                    O trabalho de cobrança leva o Exu até a pessoa que te deve para manipular seus pensamentos e tentar força-la a te pagar, 
                    e caso não seja possível obter o pagamento, a pessoa será amaldiçoada e perderá tudo que tem, chegando a miséria.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        </Row>
        <Row>
        <Col>
            <Card className="cardTrabalhos"> 
                <Card.Img variant="top" src={Daemons} />
                <Card.Body>
                    <Card.Title className="cardTitle">Trabalhos com Daemons</Card.Title>
                    <Card.Text>
                        Cerimônia Ritualística com Daemons da Goétia que você pode fazer até 2 pedidos.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Dominio} />
                <Card.Body>
                    <Card.Title className="cardTitle">Domínio de Pensamentos</Card.Title>
                    <Card.Text>
                    Com o domínio dos pensamentos, você poderá implantar na mente da pessoa todos os seus desejos, 
                    a fim de que ela caia em tentação e faça aquilo que você pediu.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Equilibrio} />
                <Card.Body>
                    <Card.Title className="cardTitle">Equilibrio Energético</Card.Title>
                    <Card.Text>
                    Esse trabalho coloca o casal em harmonia e sintonia para que ambos possuam o mesmo desejo.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
      </Row>
      <Row>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Exupombagira} />
                <Card.Body>
                    <Card.Title className="cardTitle">Trato com Exu ou Pombagira</Card.Title>
                    <Card.Text>
                    Realizando um trato com Exu ou Pombagira, você pode pedir aquilo que você mais deseja! Se for pedir algo específico, deve ser realizado com no mínimo
                    10 dias de antecedência.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Fofoqueiro} />
                <Card.Body>
                    <Card.Title className="cardTitle">Derruba Fofoqueiro</Card.Title>
                    <Card.Text>
                    Esse trabalho irá punir a pessoa que ousar falar mal de você com desgraças.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Limpeza} />
                <Card.Body>
                    <Card.Title className="cardTitle">Limpeza Espiritual e Quebra de Demanda</Card.Title>
                    <Card.Text>
                    Está se sentindo sobrecarregado? Fizeram algum trabalho contra você? Precisa de uma limpeza espiritual? 
                    O trabalho de quebra de demanda com limpeza é ideal para esses casos.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        </Row>
        <Row>
        <Col>
            <Card className="cardTrabalhos"> 
                <Card.Img variant="top" src={Maldicao} />
                <Card.Body>
                    <Card.Title className="cardTitle">Maldição</Card.Title>
                    <Card.Text>
                        O trabalho de Maldição derruba todos os caminhos da pessoa (financeiro, amoroso, familiar, amizades, saúde) de forma progressiva.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Maoaberta} />
                <Card.Body>
                    <Card.Title className="cardTitle">Mão Aberta</Card.Title>
                    <Card.Text>
                    O trabalho de Mão Aberta tem como objetivo fazer o homem te dar dinheiro, te bancar, ter o famoso “sugar daddy”. 
                    Muito indicado para mulheres que se relacionam com um homem de alto padrão, e também para as “mulheres da noite”.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Virapensamento} />
                <Card.Body>
                    <Card.Title className="cardTitle">Vira Pensamento</Card.Title>
                    <Card.Text>
                    Esse trabalho altera o sentimento ou pensamento de uma pessoa no intuito de melhorar ideias ou ações ruins.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
      </Row>
      <Row>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Orgulho} />
                <Card.Body>
                    <Card.Title className="cardTitle">Quebra de Orgulho</Card.Title>
                    <Card.Text>
                    Algumas pessoas são muito orgulhosas, e esse trabalho se certifica de quebrar o orgulho e o amargor da pessoa.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={PactoPrincipes} />
                <Card.Body>
                    <Card.Title className="cardTitle">Pacto com Príncipes Coroados</Card.Title>
                    <Card.Text>
                    Os príncipes coroados são Belzebu, Lúcifer e Astaroth. Com esse pacto, cada um deles pode te ajudar a prosperar em
                    áreas específicas da sua vida.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={TrabalhoPrincipes} />
                <Card.Body>
                    <Card.Title className="cardTitle">Trabalho com Príncipes Coroados</Card.Title>
                    <Card.Text>
                    Os príncipes coroados são Belzebu, Lúcifer e Astaroth. Com esse trabalho, cada um deles pode realizar o seu desejo.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        </Row>
        <Row>
        <Col>
            <Card className="cardTrabalhos"> 
                <Card.Img variant="top" src={Vendas} />
                <Card.Body>
                    <Card.Title className="cardTitle">Trabalho de Vendas</Card.Title>
                    <Card.Text>
                    Você trabalha com vendas e quer uma ajuda espiritual para melhorar os seus resultados? O trabalho de 
                    vendas te permite atrair mais clientes e obter mais sucesso em suas vendas.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Chora} />
                <Card.Body>
                    <Card.Title className="cardTitle">Chora aos meus Pés</Card.Title>
                    <Card.Text>
                    Esse trabalho tem como intuito específico a pessoa te procurar.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardTrabalhos">
                <Card.Img variant="top" src={Morte} />
                <Card.Body>
                    <Card.Title className="cardTitle">Trabalho de Morte</Card.Title>
                    <Card.Text>
                    Ceife a vida daquela pessoa. Trabalho realizado com Pombagira Menina ou Daemons, mediante consulta.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
      </Row>
      </Container>
    </section>
  );
}

import { Container, Row, Col, Button, Card} from "react-bootstrap";
import '../App.css';

export const Presenciais = () => {
  return (
    <section className="presenciais" id="presenciais">
    <Container>
      <Row>
        <Col>
        <h4 className="h4Title">Trabalhos Presenciais</h4>
            <p>Alguns trabalhos espirituais podem ser realizados presencialmente. Se o cliente
              desejar vir até o nosso <b>Terreiro</b>, podem ser feitos os trabalhos de: <br />
              - Limpeza Espiritual<br />
              - Quebra de Demandas<br />
              - Abertura de Caminhos<br />
            </p>
            <p>Podemos também nos deslocar até a sua empresa para realizar os trabalhos: <br />
            - Assentamento de Exu para trazer Prosperidade Permanente <br />
            - Trabalho de prosperidade para o ambiente com duração de 6 meses a 1 ano.
            </p>
        </Col>
        <Col>
          <Card className="cardPresencial">
            <Card.Body>Limpeza</Card.Body>
          </Card>
          <Card className="cardPresencial">
            <Card.Body>Quebra de Demandas</Card.Body>
          </Card>
          <Card className="cardPresencial">
            <Card.Body>Abertura de Caminhos</Card.Body>
          </Card>
          <Card className="cardPresencial">
            <Card.Body>Assentamento de Exu para Prosperidade Permanente</Card.Body>
          </Card>
          <Card className="cardPresencial">
            <Card.Body>Trabalhos de Prosperidade</Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </section>
  );
}

import { Container, Row, Col, Image, Accordion, Card } from "react-bootstrap";
import '../App.css';
import Juntos from '../assets/img/juntos.jpg';

export const Quimbanda = () => {
  return (
    <section className="quimbanda" id="quimbanda">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={4} className="text-center">
            <Image src={Juntos} className="imgQuimbanda" />
          </Col>
          <Col xs={12} md={8}>
            <div className="txtQuimbanda">
              <h4 className="h4Title">Iniciação na Quimbanda</h4>
              <h3 className="h3Subtitle">Como posso iniciar?</h3>
              <Row className="rowQuimbanda">
                <Col>
                <Card className="cardQuimbanda">
                  <Card.Body><strong>Mona Nkisi</strong> <br/>
                  O adepto deverá responder a um questionário prévio e passar por consulta de jogo de búzios para verificar 
                  os caminhos de iniciação, identificar Exu e/ou Pombagira que estão à frente, conhecer seus respectivos reinos e 
                  as oferendas necessárias. Após cumprir todas essas etapas, está liberado para ingressar no culto e se
                  tornar um Mona Nkisi (filho da casa).
                  </Card.Body>
                </Card>
                </Col>
                <Col>
                <Card className="cardQuimbanda">
                  <Card.Body><strong>Tata ou Mameto Ndenge</strong> <br/>
                  Após um ano, com a incorporação bem estabelecida e os guias espirituais estando aptos a atividades como beber, 
                  fumar, riscar ponto e dar consultas, pode-se proceder com o primeiro assentamento, seguindo orientações do jogo de búzios, e realizar a prova de fogo. 
                  Após cumprir todas essas etapas, será nomeado Tata ou Mameto Ndenge.
                  </Card.Body>
                </Card>
                </Col>
              </Row>
              <Row className="rowQuimbanda">
                <Col>
                <Card className="cardQuimbanda">
                  <Card.Body><strong>Tata ou Mameto Kivonda</strong> <br/>
                  Com a liberação do Exu 7 Encruzilhadas, será realizado o ritual para que o adepto possa receber a "mão de corte".
                  O ritual envolve certos preceitos, o recebimento da faca, a montagem do fio de contas conforme a orientação do Exu, e a sacralização tanto no fio quanto no assentamento do Exu 7 Encruzilhadas e do seu Exu de frente.
                  Após todas as etapas, você receberá o primeiro título.
                  </Card.Body>
                </Card>
                </Col>
                <Col>
                <Card className="cardQuimbanda">
                  <Card.Body><strong>Tata ou Mameto Kisaba</strong> <br/>
                  Com a liberação do Exu Pantera Negra, será realizada a iniciação para que o adepto possa utilizar ervas em assentamentos e outros fundamentos. O ritual envolve banhos específicos nos sete reinos, montagem do fio de contas conforme orientação do Exu, e sacralização tanto no fio quanto nos assentamentos do Exu Pantera Negra e do seu Exu de frente.
                  Após todas as etapas, você receberá o segundo título.
                  </Card.Body>
                </Card>
                </Col>
              </Row> 
              <Row className="rowQuimbanda">
                <Col>
                <Card className="cardQuimbanda">
                  <Card.Body><strong>Tata ou Mameto Nganga Muzambo</strong> <br/>
                  Com a liberação do Exu 7 Catacumbas, será realizada a iniciação para que o adepto possa receber o jogo de búzios de Exu.
                  O ritual envolve o recebimento do fio de contas e búzios consagrados, que deverão ser aceitos nos sete reinos, banhados conforme a orientação do Exu, e sacralizados junto ao assentamento do Exu 7 Catacumbas e do seu Exu de frente.
                  Após todas as etapas, você receberá o terceiro título.
                  </Card.Body>
                </Card>
                </Col>
                <Col>
                <Card className="cardQuimbanda">
                  <Card.Body><strong>Tata ou Mameto de Nkisi</strong> <br/>
                  Após um mínimo de 3 anos, se houver caminhos para o sacerdócio, o adepto passará por uma prova escrita e 
                  oral de conhecimentos gerais bem aprofundada e deverá ter todos os assentamentos de seus guias.
                  O ritual consiste em realizar as obrigações de Exu em todos os reinos conforme a orientação pelo 
                  jogo de búzios e as obrigações dos Maiorais. Após todas as etapas, você receberá seu Ekete e se tornará um 
                  Tata ou Mameto de Nkisi e poderá ter seu próprio templo ou terreiro.
                  </Card.Body>
                </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
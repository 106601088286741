import { Container, Row, Col, Button, Card} from "react-bootstrap";
import '../App.css';
import Buzios from '../assets/img/buziosjogo.webp'
import Perfume from '../assets/img/perfume.png'
import Guias from '../assets/img/guias.jpeg'

export const Consagrados = () => {
  return (
    <section className="consagrados" id="consagrados">
    <Container>
    <h4 className="h4Title">Itens Compráveis</h4>
    <p className="pDescription">Sob encomenda, você pode adquirir nossos produtos.</p>
      <Row>
        <Col>
            <Card className="cardConsagrados">
                <Card.Img variant="top" src={Buzios} />
                <Card.Body>
                    <Card.Title className="cardTitle">Jogo de Búzios</Card.Title>
                    <Card.Text>
                    Buzios consagrados por exu para quem deseja iniciar atendimento com este oráculo.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardConsagrados">
                <Card.Img variant="top" src={Perfume} />
                <Card.Body>
                    <Card.Title className="cardTitle">Perfumes</Card.Title>
                    <Card.Text>
                    Perfumes voltados a atração, poder, magnetismo sexual e amoroso.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardConsagrados">
                <Card.Img variant="top" src={Guias} />
                <Card.Body>
                    <Card.Title className="cardTitle">Guias de Proteção</Card.Title>
                    <Card.Text>
                    Guiadas de prosperidade e de proteção.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
      </Row>
    </Container>
    </section>
  );
}

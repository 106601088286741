import { Container, Row, Col, Button, Card} from "react-bootstrap";
import '../App.css';
import Baralho from "../assets/img/trabalhos/baralho.png";
import Buzios from "../assets/img/trabalhos/buzios.png";
import Tarot from "../assets/img/trabalhos/tarot.png";

export const Consultas = () => {
  return (
    <section className="consultas" id="consultas">
    <Container className="consultas">
    <h4 className="h4Title">Consultas</h4>
    <p className="pDescription">Utilizamos Baralho Cigano, Tarot ou Búzios, a depender da sua escolha</p>
      <Row>
        <Col>
            <Card className="cardConsultas">
                <Card.Img variant="top" src={Baralho} />
                <Card.Body>
                    <Card.Title className="cardTitle">Caminhos</Card.Title>
                    <Card.Text>
                    Tire suas dúvidas e verifique, junto ao mestre no oráculo, o trabalho ideal para o seu caso, 
                    via foto e áudio.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardConsultas">
                <Card.Img variant="top" src={Tarot} />
                <Card.Body>
                    <Card.Title className="cardTitle">Perguntas e Respostas</Card.Title>
                    <Card.Text>
                    Formule suas peguntas de forma objetiva e o mestre irá respondê-las ao longo do dia, por foto e áudio.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card className="cardConsultas">
                <Card.Img variant="top" src={Buzios} />
                <Card.Body>
                    <Card.Title className="cardTitle">Guias Espirituais</Card.Title>
                    <Card.Text>
                    Descubra seu Exu e/ou Pombagura de frente e as orientações que querem te passar.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
      </Row>
    </Container>
    </section>
  );
}

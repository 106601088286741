import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/head02.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import '../App.css';

export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col>
              <div>
                <h1>Casa de Maria Mulambo</h1>
                <p>Descubra o poder da Quimbanda Luciferiana</p>
              </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo.png';
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  // Função para remover o fragmento da URL
  const handleRemoveHash = () => {
    setTimeout(() => {
      window.history.replaceState(null, null, window.location.pathname);
    }, 100); // Timeout para dar tempo de navegar antes de remover
  }

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link 
              as={HashLink} 
              to="/#home" 
              className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} 
              onClick={() => { onUpdateActiveLink('home'); handleRemoveHash(); }}>
              Início
            </Nav.Link>
            <Nav.Link 
              as={HashLink} 
              to="/#about" 
              className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'} 
              onClick={() => { onUpdateActiveLink('about'); handleRemoveHash(); }}>
              Sobre Nós
            </Nav.Link>
            <Nav.Link 
              as={HashLink} 
              to="/allJobs#allJobs" // Use o HashLink com o fragmento
              className={activeLink === 'trabalhos' ? 'active navbar-link' : 'navbar-link'} 
              onClick={() => { onUpdateActiveLink('trabalhos'); handleRemoveHash(); }}>
              Trabalhos Espirituais
          </Nav.Link>
            <Nav.Link 
              as={HashLink} 
              to="/#consultas" 
              className={activeLink === 'consultas' ? 'active navbar-link' : 'navbar-link'} 
              onClick={() => { onUpdateActiveLink('consultas'); handleRemoveHash(); }}>
              Consultas
            </Nav.Link>
            <Nav.Link 
              as={HashLink} 
              to="/#consagrados" 
              className={activeLink === 'consagrados' ? 'active navbar-link' : 'navbar-link'} 
              onClick={() => { onUpdateActiveLink('consagrados'); handleRemoveHash(); }}>
              Itens Compráveis
            </Nav.Link>
          </Nav>
          <span className="navbar-text">
            <HashLink to='/#about' onClick={handleRemoveHash}>
              <button className="vvd"><span>Contato</span></button>
            </HashLink>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

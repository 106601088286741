import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo.png";
import navIcon1 from "../assets/img/nav-icon1.ico";
import navIcon2 from "../assets/img/nav-icon2.png";
import navIcon3 from "../assets/img/nav-icon3.png";
import LinkTree from "../assets/img/linktree-white-icon.webp";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
            <p>Casa de Maria Mulambo. | Desenvolvido por <a href="https://www.linkedin.com/in/guilherme-paes-7b5591133/" className="guiPaes">Guilherme Paes</a>.</p>
          </Col>
          <Col size={12} sm={3} className="text-center text-sm-end">
            <div className="social-icon">
            <p>Tata Bruno de Mulambo</p>
            <h5 className="cardTitle">Redes Sociais</h5>
              <a href="https://linktr.ee/tatabrunodemulambo" target="_blank"><img src={LinkTree} alt="LinkTree" /></a>
            </div>
          </Col>
          <Col size={12} sm={3} className="text-center text-sm-end">
            <div className="social-icon">
            <p>Tata Caio de Pinga Fogo</p>
            <h5 className="cardTitle">Redes Sociais</h5>
              <a href="https://linktr.ee/ccanassa" target="_blank"><img src={LinkTree} alt="LinkTree" /></a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

